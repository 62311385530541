
.UniNavBarContainer {
  background-color: #000;
  width: 100%;
  height: 3vh;
  border-bottom: 1px solid #80808061;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: clamp(0.7rem, 0.9vw, 1.1rem);
  font-family: Roboto;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 1% 0;

}

.UniLogoAndLabel {
  
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin-left: 1rem;
  &>img{
    max-width: 30px;
    max-height:30px;
  }
  &>span{
    font-size: clamp(1rem, 3vw, 1.9rem);
  }
}

.UniNavBarTabs {
  display: flex;
  justify-content: center;
  /* width: 45%; */
  flex: 2 1;
  gap: 7%;
  max-width: 70%;
  // padding-right: 8rem;

  >span:hover {
    cursor: pointer;
  }

  >span:hover {
    background: linear-gradient(to right, #1c99fe, #7644ff, #fd4766);
    width: -moz-fit-content;
    width: fit-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.UniNavBarUtils {
  display: flex;
  gap: 10px;
  width: 20%;
  height: 90%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 2% 0 0;

  >span {
    cursor: pointer;
  }
}

.UniNavBarMenu {
  >:nth-child(2) {
    display: none;
  }

  display: flex;
  height: 100%;
  align-items: center;
}

@media (max-width: 890px) {
  .UniNavBarTabs {
    display: none;
  }

  .UniNavBarMenu {
    >:nth-child(2) {
      display: block;
    }
  }

  // .UniNavBarContainer {
  //   gap: 50%;
  // }

}

@media only screen and (max-width: 500px) {
  .UniNavBarContainer {
    height: 8vh;
    justify-content: space-between;
    // min-width: 400px;
  }
}

// .UniNavBarUtils .UniNavBarSearchBar {
//   border-radius: 50px;
//   background-color: rgba(255, 255, 255, 0.284);
//   padding: 0 0 0 1rem;
//   color: white;
//   font-weight: 400;
//   letter-spacing: 1px;
//   font-size: 16px;
//   position: relative;
//   // left: -265px;
//   width: 0px;
//   height: 56px;
//   left: 67px;
//   overflow: hidden;
//   outline: none;
//   border: none;
//   opacity: 0;
//   transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
//   visibility: hidden;

//   &::placeholder {
//     color: white;
//     font-size: 15px;
//   }
// }

// .UniNavBarUtils .UniNavBarSearchBar.OpenSearchBar {
//   width: 50%;
//   opacity: 1;
//   visibility: visible;
//   transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
// }

// .SearchButton {
//   transition: opacity 1s ease-in-out;
// }

// .Hidden {
//   opacity: 0;
//   transition: display 0.6s ease-in-out;
// }

// @media only screen and (max-width: 1096px) {
//   .UniNavBarContainer {
//     // flex-direction: column;
//     height: 17%;
//     gap: 10%;
//     padding: 3% 0;
//     justify-content: flex-end;
//   }

//   .UniLogoAndLabel {
//     margin: 1% 0 0% 0%;
//     width: fit-content;
//   }

//   .UniNavBarUtils {
//     height: 50%;
//     margin: 1% 0 0 0;
//     width: 100%;
//   }

//   .UniNavBarTabs {
//     display: none;
//   }
// }